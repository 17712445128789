import { CircularProgress } from "@mui/material";

const SpinnerOverlay = ({ show, size, body }) => {
    return (
        <>
            {
                show &&
                <div
                    tabIndex={-1}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f0f0f0",
                        cursor: "wait",
                        zIndex: 99
                    }}
                >
                    <CircularProgress size={size} style={{ marginRight: 15, color: "darkgray" }} aria-label="Please wait" />
                    <div style={{ fontSize: size, fontWeight: "bold" }}>
                        {body}
                    </div>
                </div>
            }
        </>
    )
}

export default SpinnerOverlay;