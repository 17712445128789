import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import SpinnerOverlay from './components/SpinnerOverlay';
import Home from './Home';
import Landing from './Landing';
import { oktaAuth } from './services/apiService';

const ErrorComponent = ({ error }) => {
  useEffect(() => {
    window.location.href = "/";
    window.location.reload();
  });

  return (
    <div>{error}</div>
  )
}

const App = () => {
  const [loaded, setLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const verifyLogin = useCallback(async () => {
    const authenticated = await oktaAuth.isAuthenticated();

    if (authenticated) {
      setLoggedIn(true);
    }

    setLoaded(true);
  }, [])

  useEffect(() => {
    verifyLogin();
  }, [verifyLogin]);

  const restoreOriginalUri = useCallback(() => {
    window.location.href = "/";
  }, [])

  const customAuthHandler = useCallback(async () => {
    const authenticated = await oktaAuth.isAuthenticated();

    setLoggedIn(authenticated);
  }, []);

  if (!loaded) {
    return <SpinnerOverlay show />
  }

  return (
    <BrowserRouter>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
        onError={() => setLoggedIn(false)}
      >
        {
          loggedIn ?
            <SecureRoute path="/" component={Home} />
            :
            <>
              <Route path="/callback">
                <LoginCallback errorComponent={ErrorComponent} loadingElement={<SpinnerOverlay show />} />
              </Route>
              <Route path="/" component={Landing} />
            </>
        }
      </Security>
    </BrowserRouter>
  );
};

export default memo(App);