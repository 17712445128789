import { Banner } from "@uspto-desnsys/uspto-ds-react";
import { memo, useCallback, useEffect, useState } from "react";
import { oktaAuth, verifyAdminAccess } from "../services/apiService";
import { EditNote } from "@mui/icons-material";

/** USPTO Header */
const GlobalHeader = ({ onEditApplicationsClick }) => {
    const [userName, setUserName] = useState("USPTO User");
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        oktaAuth.getUser().then(u => setUserName(u.name || "USPTO User"));

        verifyAdminAccess()
            .then(response => {
                setIsAdmin(response.ok);
            })
    }, []);

    const signout = useCallback(() => {
        oktaAuth.signOut();
    }, []);

    return (
        <>
            {/* USPTO Banner */}
            <div className="bg-light">
                <div className="container">
                    <Banner />
                </div>
            </div>

            <header id="global-header">
                <div className="uspto-header-top">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-dark" style={{ padding: "4px 0" }}>
                            <a href="https://www.uspto.gov" target="_blank" rel="noreferrer" className="uspto-logo ml-0" title="United States Patent and Trademark Office - An Agency of the Department of Commerce">
                                <span className="sr-only">
                                    United States Patent and Trademark Office
                                </span>
                            </a>
                            <div className="ml-auto d-flex">
                                <div className="display-inline dyn-menuarea"></div>
                                <div className="btn-group">
                                    <a className="btn btn-default ex-btn-inverse my-link" href="https://my.uspto.gov">
                                        <span className="btn-text">MyUSPTO</span>
                                        <span className="btn-icon">
                                            <span className="uspto-icon-myuspto"></span></span>

                                    </a>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className="btn btn-default dropdown-toggle ex-btn-inverse user-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="btn-text">{userName}<span className="caret"></span></span>
                                        <span className="btn-icon">
                                            <span className="uspto-icon-user"></span></span>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li>
                                            <a href="https://my.uspto.gov">
                                                <span className="uspto-icon-home"></span> MyUSPTO<span className="sr-only">console</span></a>
                                        </li>
                                        <li>
                                            <a href="https://account.uspto.gov">
                                                <span className="uspto-icon-user-white mr-2"></span>
                                                <span className="sr-only">Manage your</span>Account</a>
                                        </li>
                                        {
                                            isAdmin &&
                                            <li>
                                                <button
                                                    className="btn link"
                                                    style={{ paddingLeft: 13, width: "100%", textAlign: "left", fontSize: 14 }}
                                                    onClick={onEditApplicationsClick}
                                                >
                                                    <EditNote fontSize="small" style={{ color: "white", marginRight: -2 }} /> Edit App List
                                                </button>
                                            </li>
                                        }
                                        <li>
                                            <button
                                                className="btn link"
                                                style={{ paddingLeft: 16, width: "100%", textAlign: "left", fontSize: 15 }}
                                                onClick={signout}
                                            >
                                                <span className="uspto-icon-sign-out"></span> Sign out
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="uspto-header-middle">
                    <div className="flex-fill container">
                        <div className="py-0" title="Project title">
                            <span style={{
                                fontSize: "2rem",
                                fontWeight: 300,
                                color: "#444"
                            }}
                            >
                                Labs
                            </span>
                        </div>
                    </div>
                </div>
                <div className="uspto-header-bottom">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-dark " style={{ padding: 0 }} aria-label="header sub navigation">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav mr-auto">
                                    <i className="material-icons sm-md text-white mr-2">home</i>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}

export default memo(GlobalHeader);