import { memo, useCallback, useEffect, useState } from "react";
import { adminFetchApplicationList, adminSaveApplication } from "../services/apiService";
import { Button } from "@uspto-desnsys/uspto-ds-react";
import AppItemData from "./AppItemData";
import ApplicationEditForm from "./ApplicationEditForm";
import BusyOverlay from "./BusyOverlay";

const ApplicationEditor = ({ onCloseEditor }) => {
    const [busy, setBusy] = useState(false);
    const [appListActive, setAppListActive] = useState(null);
    const [appListDisabled, setAppListDisabled] = useState(null);
    const [editorActiveForm, setEditorActiveForm] = useState(null);

    // Fetch latest application list
    const fetchAppList = useCallback(async () => {
        try {
            setBusy(true);
            const response = await adminFetchApplicationList();

            if (response.ok) {
                const appList = await response.json();

                setAppListDisabled(appList?.filter(a => a?.disabled));
                setAppListActive(appList?.filter(a => !a?.disabled));
            } else {
                alert("There was a problem fetching the application list. Response code " + response.status);
            }
        } finally {
            setBusy(false);
        }
    }, []);

    // Enable/disable an application
    const toggleDisableApplication = useCallback(async (app) => {
        try {
            if (!app) return;
            setBusy(true);

            const updated = {
                ...app,
                disabled: !app.disabled
            }

            const response = await adminSaveApplication(updated);

            if (response.ok) {
                fetchAppList();
            } else {
                alert(`There was an error saving the application: [${response.status}] ${response.statusText}`)
            }
        } finally {
            setBusy(false);
        }
    }, [fetchAppList]);

    const deleteApplication = useCallback(async (app, force) => {
        try {
            setBusy(true);

            if (!force) {
                const promptMessage = `${app.name} will be deleted.\n\nAre you sure?`;

                if (!window.confirm(promptMessage)) {
                    return;
                }
            }

            const updated = {
                ...app,
                deleted: true
            }

            const response = await adminSaveApplication(updated);

            if (response.ok) {
                fetchAppList();
            } else {
                alert(`There was an error deleting the application: [${response.status}] ${response.statusText}`)
            }
        } finally {
            setBusy(false);
        }
    }, [fetchAppList]);

    useEffect(() => {
        !appListActive && fetchAppList();
    }, [appListActive, fetchAppList]);

    if (editorActiveForm) {
        return (
            <ApplicationEditForm
                application={editorActiveForm}
                allApps={[...appListActive, ...appListDisabled]}
                onCloseEditor={(deleteOriginal) => {
                    if (deleteOriginal) {
                        deleteApplication(editorActiveForm, true);
                    } else {
                        fetchAppList();
                    }

                    setEditorActiveForm(null);
                }}
            />
        )
    }

    return (
        <div>
            <BusyOverlay busy={busy} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <Button variant="secondary" onClick={onCloseEditor}>Exit Application Editor</Button>
                <Button onClick={() => setEditorActiveForm({})}>Add New Application</Button>
            </div>
            {
                appListActive &&
                <div style={{ margin: "40px 0" }}>
                    <h1 style={{ fontSize: 24 }}>
                        Active Labs Applications
                    </h1>
                    {
                        appListActive.sort((a, b) => a.name.localeCompare(b.name)).map((app) => (
                            <div
                                key={app.path}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 10,
                                    margin: "10px 0",
                                    border: "1px solid gray",
                                }}
                            >
                                <AppItemData app={app} />
                                <div
                                    className="row"
                                    style={{
                                        margin: "5px 0",
                                        flexBasis: 450,
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Button
                                        onClick={() => setEditorActiveForm(app)}
                                    >
                                        Edit <span className="sr-only">{app.name}</span>
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() => toggleDisableApplication(app)}
                                    >
                                        Disable <span className="sr-only">{app.name}</span>
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={() => deleteApplication(app)}
                                    >
                                        Delete <span className="sr-only">{app.name}</span>
                                    </Button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
            {
                appListDisabled &&
                <div style={{ margin: "20px 0" }}>
                    <h1 style={{ fontSize: 24 }}>
                        Disabled Labs Applications
                    </h1>
                    {
                        appListDisabled.sort((a, b) => a.name.localeCompare(b.name)).map((app) => (
                            <div
                                key={app.path}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 10,
                                    margin: "10px 0",
                                    border: "1px solid gray",
                                    backgroundColor: "#eeeeee"
                                }}
                            >
                                <AppItemData app={app} />
                                <div
                                    className="row"
                                    style={{
                                        margin: "5px 0",
                                        flexBasis: 450,
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <Button
                                        onClick={() => setEditorActiveForm(app)}
                                    >
                                        Edit <span className="sr-only">{app.name}</span>
                                    </Button>
                                    <Button
                                        variant="success"
                                        onClick={() => toggleDisableApplication(app)}
                                    >
                                        Enable <span className="sr-only">{app.name}</span>
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={() => deleteApplication(app)}
                                    >
                                        Delete <span className="sr-only">{app.name}</span>
                                    </Button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default memo(ApplicationEditor);