import { ArrowForward, Info, Science } from "@mui/icons-material";
import { Banner } from "@uspto-desnsys/uspto-ds-react";
import { memo, useCallback } from "react";
import LabsBackground from "./resources/labs-hero-light.jpg";
import LabsLogo from "./resources/uspto-labs-logo.png";
import { oktaAuth } from "./services/apiService";

const Landing = () => {
    const triggerLogin = useCallback(async () => {
        await oktaAuth.signInWithRedirect();
    }, []);

    return (
        <div className="parent-container bg-light">
            <a className="skipnav" href="#main-content"> Skip to main content </a>
            {/* USPTO Banner */}
            <div className="bg-dark">
                <div className="container">
                    <Banner variant="dark" />
                </div>
            </div>
            <header id="global-header"> </header>
            <div className="main-container">
                <main id="main-content" className="">
                    <div
                        className="jumbotron jumbotron-fluid pl-main"
                        style={{
                            paddingTop: 150,
                            paddingBottom: 150,
                            background: `url(${LabsBackground}) no-repeat center`,
                            backgroundSize: "cover"
                        }}
                    >
                        <div className="container">
                            <div className="text-center pl-sub-main" style={{ margin: 10 }}>
                                <img src={LabsLogo} alt="USPTO Labs logo" style={{ width: 350 }} />
                            </div>
                            <h1 className="text-center text-white pl-sub-main" style={{ fontSize: 16 }}>
                                The home for our latest demo apps, experimental tools, research initiatives, and new technologies.
                            </h1>
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <div style={{ marginTop: 50, marginBottom: 15 }}>
                                        <button
                                            className="btn btn-light btn-lg"
                                            title="Click here to expore available apps"
                                            onClick={triggerLogin}
                                        >
                                            Expore available apps <ArrowForward />
                                        </button>
                                        <div
                                            className="small"
                                            style={{
                                                opacity: 0.95,
                                                marginTop: 10,
                                                color: "#fff"
                                            }}>
                                            A USPTO.gov account is required for access
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-sm-6 p-4">
                                <h1 style={{ fontSize: 20 }}>
                                    <Info style={{ paddingBottom: 4 }} />
                                    About USPTO Labs
                                </h1>
                                <p>
                                    Welcome to the USPTO Innovation Lab.
                                    Here you can try out our latest demo apps before their official release, test out new tools,
                                    and dive into research initiatives we've been working on.
                                    Join us on our product testing journey - your valuable feedback helps us improve our products.
                                </p>
                                <p>
                                    <button className="btn btn-link" onClick={triggerLogin}>
                                        View available demo apps in the lab <ArrowForward style={{ fontSize: 15 }} />
                                    </button>
                                </p>
                            </div>
                            <div className="col-sm-6 p-4">
                                <h1 style={{ fontSize: 20 }}>
                                    <Science style={{ paddingBottom: 4 }} />
                                    Continuously Evolving
                                </h1>
                                <p>
                                    USPTO Labs is continuously evolving - be sure to check back often.
                                    Demo apps, tools, and initiatives are released when available, and will rotate frequently based on product development cycles.
                                </p>
                                <p>
                                    While exploring apps in the lab, you may encounter bugs, issues or other abnormalities.
                                    We encourage you to provide your feedback on issues you encounter.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-4 mb-5">
                            <div className="col-sm-12 p-4">
                                <h1 style={{ fontSize: 20 }}>
                                    Accessing the Labs
                                </h1>
                                <p>
                                    A USPTO.gov account is required to access USPTO Labs.
                                    If you do not have an account, you can <a href="https://my.uspto.gov" target="myuspto">create a MyUSPTO.gov account here</a>.
                                    There is no cost to create a USPTO.gov account.
                                    Not all visitors will have access to every app or experiment within the lab.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <footer className="footer py-3 text-white">
                <div className="container text-center">
                    <p>Need help or having access issues?</p>
                    <p>
                        Contact us at
                        {" "}
                        <a
                            href="mailto:labs@uspto.gov"
                            target="_blank"
                            rel="noreferrer"
                            className="text-white"
                            style={{ opacity: .9 }}
                        >
                            labs@uspto.gov
                        </a>
                    </p>
                </div>
            </footer>
        </div>
    )
}

export default memo(Landing);