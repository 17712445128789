export const dynamicTransMenu100 = {
    "Patents": {
        "ulClass":"patent-menu",
        "ulIcon":"icon-lightbulb-o",
        "ulLink": [

         {
          "label": "Search patents",

          "url": "http://patft.uspto.gov/netahtml/PTO/search-bool.html"

         },
   {
          "label": "Patent applications search",

          "url": "http://appft.uspto.gov/netahtml/PTO/search-bool.html"

         },
   {
          "label": "Check private filing status",

          "url": "https://ppair-my.uspto.gov/pair/PrivatePair"

         } ,
   {
          "label": "Check public filing status",

          "url": "http://portal.uspto.gov/pair/PublicPair"

         } ,
   {
          "label": "File patents",

          "url": "https://efs-my.uspto.gov/EFSWebUIRegistered/EFSWebRegistered"

         } ,
   {
          "label": "Patent and Trial Appeal Board",

          "url": "https://ptab.uspto.gov/#/login"

         } ,
   {
          "label": "Search assignment",

          "url": "https://assignment.uspto.gov/patent/index.html#/patent/search"

         } ,
   {
          "label": "Record assignment",

          "url": "https://epas.uspto.gov/"

         } ,
   {
          "label": "Manual of Patent Examining Procedure",

          "url": "http://www.uspto.gov/web/offices/pac/mpep/index.html"

         }
      ]

  },
   "Trademarks": {
     "ulClass":"trademark-menu",
     "ulIcon":"icon-trademark",
    "ulLink" : [

         {
          "label": "Search trademarks",

          "url": "http://tmsearch.uspto.gov/bin/gate.exe?f=login&p_lang=english&p_d=trmk"

         },
   {
          "label": "File trademark forms",

          "url": "https://www.uspto.gov/trademarks-application-process/filing-online"

         },
   {
          "label": "View status, documents, and registration certificates",

          "url": "http://tsdr.uspto.gov/"

         } ,
   {
          "label": "File Trademark Trial and Appeal Board forms",

          "url": "http://estta.uspto.gov/"

         } ,
   {
          "label": "View Trademark Trial and Appeal Board proceedings",

          "url": "http://ttabvue.uspto.gov/ttabvue/v"

         } ,
   {
          "label": "Search Trademark Official Gazette",

          "url": "https://tmog.uspto.gov/#"

         } ,
   {
          "label": "Record assignment",

          "url": "https://etas.uspto.gov/"

         } ,
   {
          "label": "Search assignment",

          "url": "https://assignment.uspto.gov/trademark/index.html#/trademark/search"

         } ,
   {
          "label": "View Trademark Manual of Examining Procedure",

          "url": "https://tmep.uspto.gov/RDMS/TMEP/current#/current/d1e2.html"

         } ,
   {
          "label": "Search the ID Manual",

          "url": "https://idm-tmng.uspto.gov/id-master-list-public.html"

         }
      ]
  },

  "Fees and payment": {
    "ulClass":"fees-menu",
    "ulIcon":"icon-dollar",
     "ulLink": [

         {
          "label": "Fees Self-Service",

          "url": "https://fees.uspto.gov/"

         },

         {
          "label": "Financial Manager",

          "url": "https://fees.uspto.gov/FinancialManager"

         },

        {
          "label": "Patent Maintenance Fees Storefront",

          "url": "https://fees.uspto.gov/MaintenanceFees"

         }
    ]
  },
  "Help": {
   "ulIcon":"icon-question-circle",
   "ulLink":[

         {
          "label": "FAQs",

          "url": "http://www.uspto.gov/learning-resources"

         },

         {
          "label": "Contact us",

          "url": "http://www.uspto.gov/about-us/contact-us"

         }
    ]
  }
  };
