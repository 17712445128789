import { memo, useCallback, useEffect, useState } from "react";
import './App.scss';
import ApplicationEditor from "./components/ApplicationEditor";
import ApplicationList from "./components/ApplicationList";
import GlobalHeader from "./components/GlobalHeader";
import { ptoDynamicHeader } from "./scripts/fetchHeaderContent";
import { oktaAuth, startSession } from "./services/apiService";

let refreshInterval;

const startRefreshInterval = (refreshTokens) => {
    clearInterval(refreshInterval);

    const callback = async () => {
        try {
            let tokens = await oktaAuth.token.renewTokens();
            oktaAuth.tokenManager.setTokens(tokens);
        } catch (error) {
            console.error(error);
        }
    }

    if (refreshTokens)
        callback();

    refreshInterval = setInterval(callback, 300000);
}

const CLOSEMESSAGE = "Launching this application will close the currently active Labs application." +
    "\n\nYou will lose any unsaved progress. Continue?";

const Home = () => {
    const [launching, setLaunching] = useState(false);
    const [activeWindow, setActiveWindow] = useState(null);
    const [activeApp, setActiveApp] = useState(null);
    const [editMode, setEditMode] = useState(false);

    // Start token refresh and load header menu
    useEffect(() => {
        ptoDynamicHeader.loadMenu();
        startRefreshInterval();
    }, []);

    // Launch an app, after some checks
    const launchApplication = useCallback(async (app) => {
        if (launching) return;

        if (activeApp === app && !activeWindow.closed) {
            activeWindow.focus();
            return;
        }

        try {
            setLaunching(true);

            // Confirm new app if there's an active window
            if (activeWindow && !activeWindow.closed) {
                if (window.confirm(CLOSEMESSAGE)) {
                    activeWindow.close();
                } else {
                    return;
                }
            }

            const sessionResponse = await startSession(app.path);

            if (sessionResponse.ok) {
                setActiveApp(app);
                setActiveWindow(window.open(app.url, "usptolabsapp"))
            } else {
                throw sessionResponse;
            }
        } catch (error) {
            console.debug(error);
        } finally {
            setLaunching(false);
        }
    }, [activeWindow, activeApp, launching]);

    return (
        <div className="App">
            <GlobalHeader onEditApplicationsClick={() => setEditMode(true)} />
            <div className="container pt-4" style={{ paddingBottom: 150 }}>
                {
                    editMode ?
                        <>
                            <ApplicationEditor onCloseEditor={() => setEditMode(false)} />
                        </>
                        :
                        <ApplicationList onLaunchApplication={launchApplication} busy={launching} />
                }
            </div>
            <footer
                className="footer py-3 text-white"
                style={{
                    opacity: 1,
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1
                }}
            >
                <div className="container text-center">
                    <div>Need help or having access issues?</div>
                    <div>
                        Contact us at {" "}
                        <a
                            href="mailto:labs@uspto.gov"
                            target="_blank" rel="noreferrer"
                            className="text-white"
                            style={{ opacity: .9 }}
                        >
                            labs@uspto.gov
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default memo(Home);
