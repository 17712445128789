import { OktaAuth } from '@okta/okta-auth-js';
import config from './config';

const apiBaseEndpoint = process.env.NODE_ENV === "development" ? "http://localhost/api/" : "/api/";
const applicationsEndpoint = apiBaseEndpoint + "applications";
const startSessionEndpoint = apiBaseEndpoint + "startSession";
const verifyAdminAccessEndpoint = apiBaseEndpoint + "verifyAccess";
const adminApplicationsEndpoint = apiBaseEndpoint + "allApplications";
const adminSaveApplicationEndpoint = apiBaseEndpoint + "saveApplication";

export const oktaAuth = new OktaAuth(config.oidc);

export const fetchApplicationList = async () => {
    return fetch(applicationsEndpoint, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + oktaAuth.getAccessToken()
        }
    });
}

export const startSession = async (path) => {
    return fetch(startSessionEndpoint + `?path=${path}`, {
        method: "GET",
        headers: {
            "Accept": "*/*",
            "Authorization": "Bearer " + oktaAuth.getAccessToken()
        }
    });
}

export const verifyAdminAccess = async () => {
    return fetch(verifyAdminAccessEndpoint, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + oktaAuth.getAccessToken()
        }
    });
}

export const adminFetchApplicationList = async () => {
    return fetch(adminApplicationsEndpoint, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + oktaAuth.getAccessToken()
        }
    });
}

export const adminSaveApplication = async (application) => {
    return fetch(adminSaveApplicationEndpoint, {
        method: "POST",
        headers: {
            "Content-Type" : "application/json",
            "Authorization": "Bearer " + oktaAuth.getAccessToken()
        },
        body: JSON.stringify(application)
    });
}