import { memo } from "react";

const BusyOverlay = ({ busy }) => busy && (
    <div
        style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 10,
            cursor: "wait"
        }}
    >
    </div>
)

export default memo(BusyOverlay);