import { memo } from "react"

const AppItemData = ({ app, ...props }) => (
    <div
        {...props}
        style={{
            ...props?.style,
            display: "flex",
            alignItems: "center",
            flexBasis: "100%",
            whiteSpace: "nowrap",
            padding: 5
        }}
    >
        <div style={{ marginLeft: 8 }}>
            <span
                style={{ fontWeight: 600, color: "darkblue" }}
            >
                {app.name}
            </span>
            {
                app.lifecycle &&
                <span
                    style={{ textTransform: "uppercase", margin: "0 5px" }}
                >
                    [{app.lifecycle}]
                </span>
            }
            {
                app.version &&
                <span
                    style={{ margin: "0 5px" }}
                >
                    [Version {app.version}]
                </span>
            }
            <div>
                <strong>Path:</strong> {app.path}
            </div>
            <div>
                <strong>URL:</strong> {app.url}
            </div>
            <div
                style={{
                    whiteSpace: "wrap"
                }}
            >
                <strong>Description:</strong> {app.description}
            </div>
        </div>
    </div>
)

export default memo(AppItemData);