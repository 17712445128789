import { memo, useCallback, useEffect, useState } from "react";
import labicon from "../resources/labicon.png";
import { fetchApplicationList } from "../services/apiService";

const ApplicationList = ({ onLaunchApplication, busy }) => {
    const [appList, setAppList] = useState(null);
    const [fetching, setFetching] = useState(false);

    const fetchApplications = useCallback(async () => {
        try {
            const response = await fetchApplicationList();

            if (response.ok) {
                const list = await response.json();
                setAppList(list);
            } else {
                setAppList([]);
            }
        } catch (e) {
            console.debug(e);
            setAppList([]);
        }
    }, []);

    useEffect(() => {
        if (!fetching) {
            setFetching(true);
            fetchApplications();
        }
    }, [fetching, appList, fetchApplications]);

    return (
        <>
            <h2> Available demo apps, experimental tools, reserach initiatives, and new technologies </h2>
            <p className="pt-1 pb-2">
                While exploring apps in the lab, you may encounter bugs, issues or other abnormalities.
                We encourage you to provide your feedback on issues you encounter.
            </p>
            <p>
                <strong>Please note: </strong>
                Only one app can be open at a time. When you are done exploring, you can return to this page to try another app.
            </p>
            {
                !appList ?
                    <h3>Fetching Available Applications...</h3>
                    :
                    appList.length === 0 ?
                        <>
                            <h3>There are no labs applications available to you at this time</h3>
                        </>
                        :
                        appList.sort((a, b) => a.name.localeCompare(b.name)).map((app, idx) => (
                            <div className="card mb-4" key={app.name + idx}>
                                <div className="card-body" style={{ display: "flex", flexWrap: "nowrap" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            flexBasis: 90
                                        }}
                                    >
                                        <img
                                            src={labicon}
                                            alt="Labs Icon"
                                            title={app.name}
                                            width="70"
                                            className="mr-3 mt-1 myImg"
                                            style={{
                                                backgroundColor: "lightgray",
                                                border: "1px solid gray",
                                                borderRadius: 5,
                                                padding: 5
                                            }}
                                        />
                                    </div>
                                    <div style={{ flexBasis: "100%", padding: "3px 10px" }}>
                                        <h3 className="d-inline-block mb-0">{app.name}</h3>
                                        {
                                            app.lifecycle &&
                                            <span
                                                className="badge badge-subtle-info ml-2"
                                            >
                                                {app.lifecycle}
                                            </span>
                                        }
                                        {
                                            app.version &&
                                            <p className="text-muted mb-0">Version {app.version}</p>
                                        }
                                        <p className="mb-0">{app.description}</p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-end",
                                            flexBasis: 220
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary font-weight-normal mt-3"
                                            style={{ width: 160 }}
                                            title={"Click to launch the app " + app.name}
                                            disabled={busy}
                                            onClick={() => onLaunchApplication && onLaunchApplication(app)}
                                        >
                                            Launch this app
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
            }
        </>
    )
}

export default memo(ApplicationList);